import React from 'react';

const IconMupati = () => (
  <svg id="logo" viewBox="0 0 547.5 226.77">
    <title>Mupati Logo</title>
    <g fill="none" className="lines" fillRule="evenodd" stroke="currentColor" strokeWidth="1">
      <g id="R" transform="translate(455.000000,18.000000)" height="10" width="100">
        <circle cx="10" cy="10" r="1.5" fill="none" stroke="#ddd29a" strokeWidth="20" />
      </g>

      <path
        className="el"
        d="m73.65 155.99a14 14 0 0 1-9.93-4.11l-28-28a13.28 13.28 0 0 1-3.9-9l0.62-0.05h-0.62a13.36 13.36 0 0 1 3.9-9l28-28.000002a14.046677 14.046677 0 0 1 19.86 19.87l-17.08 17.200002 17.11 17.12a14 14 0 0 1-9.93 24zm-32-30.29 0.2 0.19 23.94 23.94a11.16 11.16 0 1 0 15.71-15.77l-19.15-19.15 1-1 18.15-18.150002a11.161681 11.161681 0 1 0-15.78-15.79l-28 28.000002a10.41 10.41 0 0 0-3.06 6.93 10.38 10.38 0 0 0 3.06 7z"
        stroke="#ddd29a"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="arcs"
      />

      <path
        className="el"
        d="m475.71 155.99a14.05 14.05 0 0 1-9.93-24l17.12-17.11-17.11-17.120002a14.043141 14.043141 0 1 1 19.86-19.86l28 28.000002a13.28 13.28 0 0 1 3.9 9l-0.62 0.05h0.57a13.36 13.36 0 0 1-3.9 9l-28 28a13.94 13.94 0 0 1-9.89 4.04zm0-79.290002a11.16 11.16 0 0 0-7.89 19l19.15 19.150002-1 1-18.15 18.14a11.161681 11.161681 0 1 0 15.78 15.79l28-28a10.42 10.42 0 0 0 3.06-6.93 10.4 10.4 0 0 0-3.06-7l-28-28.000002a11.09 11.09 0 0 0-7.89-3.18z"
        stroke="#ddd29a"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="arcs"
      />

      <path
        className="el"
        id="stick"
        d="m400.3 177a10.46 10.46 0 0 1-4.28-0.92 10.54 10.54 0 0 1-5.31-13.91l48.65-108.710002a10.523594 10.523594 0 1 1 19.21 8.6l-48.65 108.710002a10.54 10.54 0 0 1-9.62 6.23zm-3.1-3.55a7.66 7.66 0 0 0 10.09-3.85l48.65-108.710002a7.64 7.64 0 0 0-13.94-6.22l-48.66 108.680002a7.65 7.65 0 0 0 3.85 10.09z"
        stroke="#ddd29a"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="arcs"
      />

      <path
        className="el"
        d="m210.33 148.52h-23.27v-41.07a6.1 6.1 0 0 0-1.89-4.58 6.72 6.72 0 0 0-5-1.85 6.23 6.23 0 0 0-6.7 6.82v40.67h-22.6v-40.66a6.68 6.68 0 0 0-1.82-5 6.81 6.81 0 0 0-5-1.78 6.62 6.62 0 0 0-4.88 1.78 6.16 6.16 0 0 0-1.82 4.65v41.07h-23.38v-41.12a27.86 27.86 0 0 1 8.43-20.680002c5.59-5.47 12.66-8.25 21-8.25a30.2 30.2 0 0 1 15 3.74 13.41 13.41 0 0 1 2.11 1.22q0.48 0.37 1.69 1.38a31.85 31.85 0 0 1 19-6.34 28.45 28.45 0 0 1 20.81 8.32 28 28 0 0 1 8.35 20.610002zm-20.5-2.72h17.72v-38.35a25.44 25.44 0 0 0-7.56-18.710002 25.74 25.74 0 0 0-18.83-7.51 29.35 29.35 0 0 0-18.15 6.44l-0.9 0.69-0.86-0.72q-1.85-1.55-2.45-2a11.38 11.38 0 0 0-1.72-1 27.5 27.5 0 0 0-13.67-3.42 26.26 26.26 0 0 0-19 7.45 25.27 25.27 0 0 0-7.62 18.760002v38.35h17.81v-38.33a8.83 8.83 0 0 1 2.63-6.57 9.41 9.41 0 0 1 6.85-2.570002 9.59 9.59 0 0 1 7 2.570002 9.33 9.33 0 0 1 2.63 7v38h17v-38a9 9 0 0 1 9.48-9.540002 9.53 9.53 0 0 1 6.92 2.640002 8.78 8.78 0 0 1 2.7 6.5z"
        stroke="#ddd29a"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="arcs"
      />

      <path
        className="el"
        d="m220.37 150.1c-9.49 0-17.53-3-23.88-8.83a29.14 29.14 0 0 1-9.61-22.27v-39.330002h23.42v38.500002a10.08 10.08 0 0 0 2.72 7.42 9.71 9.71 0 0 0 7.36 2.76 10 10 0 0 0 7.38-2.78 9.82 9.82 0 0 0 2.83-7.4v-38.500002h23.27v39.300002a29.1 29.1 0 0 1-9.67 22.2c-6.4 5.94-14.41 8.93-23.82 8.93zm-30.71-67.680002v36.580002a26.54 26.54 0 0 0 8.73 20.29c5.82 5.37 13.21 8.09 22 8.09s16-2.74 21.9-8.16a26.51 26.51 0 0 0 8.8-20.23v-36.570002h-17.74v35.780002a12.54 12.54 0 0 1-3.64 9.31 12.82 12.82 0 0 1-9.35 3.58 12.52 12.52 0 0 1-9.37-3.6 12.8 12.8 0 0 1-3.48-9.29v-35.780002z"
        stroke="#ddd29a"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="arcs"
      />

      <path
        className="el"
        d="m253.99 176.88h-23.41v-64.5c0-9.41 3.61-17.590002 10.74-24.290002a36.68 36.68 0 0 1 26-10.09c10.23 0 18.91 3.47 25.8 10.3s10.38 15.440002 10.38 25.610002a35 35 0 0 1-10.3 25.55c-6.85 6.88-15.46 10.37-25.61 10.37a41.9 41.9 0 0 1-10.2-1.63l-1-0.27v-22.2l1.74 0.45a30.2 30.2 0 0 0 3.46 0.76 26.42 26.42 0 0 0 2.93 0.12c5 0 8.91-1.15 11.56-3.42s3.91-5.4 3.91-9.6a12.63 12.63 0 0 0-3.66-9.34 12.36 12.36 0 0 0-9.25-3.68 12.61 12.61 0 0 0-9.27 3.7 12 12 0 0 0-3.77 9zm-20.63-2.72h17.86v-60.49a14.72 14.72 0 0 1 4.58-10.87 15.49 15.49 0 0 1 11.23-4.500002 15.23 15.23 0 0 1 11.25 4.500002 15.39 15.39 0 0 1 4.44 11.23c0 5-1.63 8.87-4.85 11.64s-7.68 4.1-13.39 4.1a28.71 28.71 0 0 1-3.27-0.14 21.15 21.15 0 0 1-2.08-0.4v16.6a37.21 37.21 0 0 0 8.45 1.28 32.57 32.57 0 0 0 33.14-33.21c0-9.43-3.22-17.410002-9.58-23.720002s-14.39-9.51-23.84-9.51a34 34 0 0 0-24.05 9.33 29.73 29.73 0 0 0-9.89 22.340002z"
        stroke="#ddd29a"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="arcs"
      />

      <path
        className="el"
        d="m314.98 150.1a33.77 33.77 0 0 1-24.8-10.26 34.36 34.36 0 0 1-10.17-25.07 35.49 35.49 0 0 1 10.79-26.100002 36.12 36.12 0 0 1 26.34-10.72c10.33 0 19 3.52 25.69 10.45s10.09 15.840002 10.09 26.530002v33.55h-23.28v-32.81c0-4.57-1.2-8.27-3.58-11a11.82 11.82 0 0 0-9.46-4 12.61 12.61 0 0 0-9.27 3.71 13.21 13.21 0 0 0 0.38 18.86 14.74 14.74 0 0 0 10.51 3.87 19.88 19.88 0 0 0 6.89-1.74l1.92-0.77v23.34l-1 0.3a38.68 38.68 0 0 1-11.05 1.86zm2.16-69.400002a33.41 33.41 0 0 0-24.36 9.91 32.89 32.89 0 0 0-10 24.160002 31.75 31.75 0 0 0 9.38 23.18 31.05 31.05 0 0 0 22.8 9.44 34.47 34.47 0 0 0 9.27-1.51v-17.33a18.85 18.85 0 0 1-6 1.23 17.66 17.66 0 0 1-12.42-4.61 15.89 15.89 0 0 1-0.44-22.75 15.49 15.49 0 0 1 11.23-4.510002 14.65 14.65 0 0 1 11.57 5.000002c2.82 3.23 4.24 7.52 4.24 12.75v30.17h17.72v-30.86c0-10-3.14-18.270002-9.33-24.660002s-14.12-9.64-23.66-9.64z"
        stroke="#ddd29a"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="arcs"
      />

      <path
        className="el"
        d="m360.42 148.77a45.82 45.82 0 0 1-11.46-1.36 24.21 24.21 0 0 1-8.59-3.8 23.77 23.77 0 0 1-7.71-10 33.71 33.71 0 0 1-2.81-13.66v-57.120002l23.41-0.15v17h11.24v21.450002h-11.24v18.87a6.82 6.82 0 0 0 2.13 5.32 8.53 8.53 0 0 0 6.06 2h3v21.06l-3.95 0.39zm-27.79-83.250002v54.460002a31.05 31.05 0 0 0 2.58 12.59 21.11 21.11 0 0 0 6.83 8.88 21.51 21.51 0 0 0 7.62 3.34 43 43 0 0 0 10.71 1.27l1.31-0.13v-15.88h-0.18a11.33 11.33 0 0 1-8-2.73 9.49 9.49 0 0 1-3-7.29v-21.590002h11.19v-16h-11.19v-17z"
        stroke="#ddd29a"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="arcs"
      />

      <path
        className="el"
        d="m361.71 93.669998v54.840002h23.41v-54.840002zm20.63 52.290002h-17.84v-49.730002h17.86z"
        stroke="#ddd29a"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="arcs"
      />

      <path
        className="el"
        d="m382.57 65.039998a13.06 13.06 0 0 0-9.22-3.67 12.91 12.91 0 0 0-9.37 3.68 12.34 12.34 0 0 0-3.75 9.15 12 12 0 0 0 3.9 9 13.06 13.06 0 0 0 9.22 3.67 12.91 12.91 0 0 0 9.37-3.68 12.34 12.34 0 0 0 3.75-9.15 12 12 0 0 0-3.9-9zm-1.75 16.35a10.15 10.15 0 0 1-7.47 2.92 10.3 10.3 0 0 1-7.37-2.93 9.36 9.36 0 0 1-3.08-7.17 9.72 9.72 0 0 1 3-7.3 10.19 10.19 0 0 1 7.48-2.92 10.3 10.3 0 0 1 7.37 2.93 9.42 9.42 0 0 1 3.08 7.17 9.67 9.67 0 0 1-3.01 7.3z"
        stroke="#ddd29a"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="arcs"
      />
    </g>
  </svg>
);

export default IconMupati;
