module.exports = {
  siteTitle: 'Kofi Mupati | Software Engineer',
  siteDescription:
    'Kofi Mupati is a Software Engineer based in Accra, Ghana who specializes in building exceptional, high-quality websites and applications',
  siteKeywords:
    'Kofi, mupati, software engineer, front-end engineer, webrtc, javascript, accra, real-time communication',
  siteUrl: 'https://kofimupati.com',
  siteLanguage: 'en_US',
  googleAnalyticsID: 'G-KGY3KCBKR8',
  googleVerification: 'nwWN3Im3oNCxMk8avdWO28qTYx6UbhdL5rew_OvUVSw',
  name: 'Kofi Mupati',
  location: 'Accra, GH',
  email: 'scientificgh@gmail.com',
  github: 'https://github.com/mupati',
  twitterHandle: '@kofi_mupati',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/mupati',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/kofiobrasiocran',
    },

    {
      name: 'Blog',
      url: 'https://dev.to/mupati',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/kofi_mupati',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Experience',
      url: '/#jobs',
    },
    {
      name: 'Work',
      url: '/#projects',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
  ],

  navHeight: 100,
  greenColor: '#64ffda',
  navyColor: '#0a192f',
  darkNavyColor: '#020c1b',

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
